import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// JSON を動的に読み込む
import Backend from "i18next-http-backend";
// 言語を自動検出
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend) // JSON をロードする
  .use(LanguageDetector) // 言語設定を自動検出（例: ブラウザの設定を優先）
  .use(initReactI18next) // React で使う
  .init({
    fallbackLng: "ja", // 言語が見つからない場合は日本語を使用
    debug: false,
    interpolation: {
      escapeValue: false, // React は XSS 対策があるのでエスケープ不要
    },
    backend: {
      loadPath: "locales/{{lng}}/{{ns}}.json", // 言語ファイルのパス
    },
    ns: ["home_page", "date_and_stats"], // ネームスペース一覧
  });

export default i18n;
