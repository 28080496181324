import React, { useEffect, useState } from "react";

import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";

import { fetchCardNames } from "./deckService";

const DeckModal = ({
  open,
  onClose,
  onSubmit,
  formState,
  setFormState,
  title,
}) => {
  const [cardNameOptions, setCardNameOptions] = useState([]);
  const [currentCardName, setCurrentCardName] = useState("");

  useEffect(() => {
    const loadCardNames = async () => {
      try {
        const data = await fetchCardNames(currentCardName);
        setCardNameOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (currentCardName.length > 0) {
      loadCardNames();
    }
  }, [currentCardName]);

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => {
      const errors = { ...prevState.errors };
      let errorMessage = "";
      if (value === null || value === undefined || value.trim() === "") {
        errorMessage = "デッキ名を入力してください";
      }
      if (name === "name_ja") {
        errors.name_ja = errorMessage;
      } else if (name === "name_en") {
        errors.name_en = errorMessage;
      }

      if (name === "name_ja") {
        return { ...prevState, name_ja: value, errors };
      } else if (name === "name_en") {
        return { ...prevState, name_en: value, errors };
      }

      return { ...prevState, errors };
    });
  };

  const handleConditionChange = (index, name, value) => {
    setFormState((prevState) => {
      const conditions = [...prevState.conditions];
      conditions[index][name] = value;
      const errors = { ...prevState.errors };
      if (
        name === "card_name" &&
        (value === null || value === undefined || value.trim() === "")
      ) {
        errors.conditions[index] = "カード名を入力してください";
      } else {
        errors.conditions[index] = "";
      }
      return { ...prevState, conditions, errors };
    });
  };

  const addCondition = () => {
    setFormState((prevState) => ({
      ...prevState,
      conditions: [...prevState.conditions, { card_name: "", num: 1 }],
    }));
  };

  const removeCondition = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      conditions: prevState.conditions.filter((_, i) => i !== index),
    }));
  };

  const handleThumbnailChange = (index, value) => {
    setFormState((prevState) => {
      const thumbnails = [...prevState.thumbnails];
      thumbnails[index] = value;
      return { ...prevState, thumbnails };
    });
  };

  const addThumbnail = () => {
    setFormState((prevState) => ({
      ...prevState,
      thumbnails: [...prevState.thumbnails, ""],
    }));
  };

  const removeThumbnail = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      thumbnails: prevState.thumbnails.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    let errors = { name_ja: "", name_en: "", conditions: [] };
    let isValid = true;

    if (!formState.name_ja) {
      errors.name_ja = "デッキ名を入力してください";
      isValid = false;
    }

    if (!formState.name_en) {
      errors.name_en = "デッキ名を入力してください";
      isValid = false;
    }

    formState.conditions.forEach((condition, index) => {
      if (!condition.card_name) {
        errors.conditions[index] = "カード名を入力してください";
        isValid = false;
      } else {
        errors.conditions[index] = "";
      }
    });

    setFormState((prevState) => ({ ...prevState, errors }));

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "70%", sm: "70%", md: 400 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" marginBottom={2}>
          {title}
        </Typography>
        <Stack gap={3}>
          {formState.errors.non_field_errors && (
            <Alert severity="error">{formState.errors.non_field_errors}</Alert>
          )}
          <Stack gap={1}>
            <Typography variant="body1" component="div">
              カード条件
            </Typography>
            <TextField
              fullWidth
              label="日本語"
              name="name_ja"
              variant="standard"
              value={formState.name_ja}
              onChange={handleNameChange}
              error={!!formState.errors.name_ja}
              helperText={formState.errors.name_ja}
              size="small"
            />
            <TextField
              fullWidth
              label="English"
              name="name_en"
              variant="standard"
              value={formState.name_en}
              onChange={handleNameChange}
              error={!!formState.errors.name_en}
              helperText={formState.errors.name_en}
              size="small"
            />
          </Stack>
          <Box>
            <Typography variant="body1" component="div">
              カード条件
            </Typography>
            <Typography variant="body2" component="div" color="grey">
              カード名：枚数（以上）
            </Typography>
            <Stack gap={1} display="flex" alignItems="flex-start">
              {formState.conditions.map((condition, index) => (
                <Box
                  display="flex"
                  alignItems="flex-end"
                  width="100%"
                  key={index}
                >
                  <Autocomplete
                    options={cardNameOptions.map((option) => option.name)}
                    renderInput={(params) => (
                      <TextField
                        margin="none"
                        {...params}
                        variant="standard"
                        size="small"
                        error={!!formState.errors.conditions[index]}
                        helperText={formState.errors.conditions[index]}
                      />
                    )}
                    name="card_name"
                    onInputChange={(event, newValue) =>
                      setCurrentCardName(newValue)
                    }
                    value={condition.card_name}
                    onChange={(event, newValue) =>
                      handleConditionChange(index, "card_name", newValue)
                    }
                    sx={{ flexGrow: 1 }}
                  />
                  <Typography variant="body2" component="span">
                    ：
                  </Typography>
                  <Select
                    name="num"
                    variant="standard"
                    value={condition.num || 1}
                    onChange={(e) =>
                      handleConditionChange(
                        index,
                        e.target.name,
                        e.target.value,
                      )
                    }
                    size="small"
                    defaultValue={1}
                  >
                    {[1, 2, 3, 4].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton
                    onClick={() => removeCondition(index)}
                    size="small"
                    edge="end"
                    sx={{
                      paddingRight: 0,
                      marginRight: -0.5,
                      paddingBottom: 0,
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <IconButton
                onClick={addCondition}
                size="small"
                variant="contained"
              >
                <AddCircleRoundedIcon sx={{ color: "#00897b" }} />
              </IconButton>
            </Stack>
          </Box>
          <Box>
            <Typography variant="body1" component="div">
              サムネイルURL
            </Typography>
            <Stack gap={1} display="flex" alignItems="flex-start">
              {formState.thumbnails &&
                formState.thumbnails.map((thumbnail, index) => (
                  <Box display="flex" width="100%">
                    <TextField
                      margin="none"
                      variant="standard"
                      value={thumbnail}
                      onChange={(e) =>
                        handleThumbnailChange(index, e.target.value)
                      }
                      sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                      onClick={() => removeThumbnail(index)}
                      size="small"
                      edge="end"
                      sx={{
                        paddingRight: 0,
                        marginRight: -0.5,
                        paddingBottom: 0,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              <IconButton
                onClick={addThumbnail}
                size="small"
                variant="contained"
              >
                <AddCircleRoundedIcon sx={{ color: "#00897b" }} />
              </IconButton>
              <Box>
                <Stack gap={0.5}>
                  <Typography variant="body2" color="gray">
                    サムネイル取得用参考リンク
                  </Typography>
                  {formState.conditions.map((condition, index) => (
                    <Link
                      href={`https://www.pokemon-card.com/card-search/index.php?keyword=${condition.card_name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <Typography variant="body2">
                        {condition.card_name}のカード検索結果
                      </Typography>
                    </Link>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ width: "150px" }}
          >
            保存
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeckModal;
