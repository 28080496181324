import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import { fetchData } from "../apiService";
import { FilterConditionContext } from "../components/FilterConditionContext";
import ChartComponent from "./ChartComponent";
import { DeckRankingTabContext } from "./DeckRankingTabContext";
import TableComponent from "./TableComponent";

export default function DeckRankingTab({
  handleTabChange,
  earliestDate,
  latestDate,
}) {
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(false);

  const deckRankingTabValue = useContext(DeckRankingTabContext);
  const { error, items } = deckRankingTabValue;
  const filterConditionContextValue = useContext(FilterConditionContext);
  const { startDate, endDate, selectedDivisions } = filterConditionContextValue;

  useEffect(() => {
    fetchData(
      filterConditionContextValue,
      deckRankingTabValue,
      setLoading,
      "deck_ranking",
    );
  }, [
    startDate,
    endDate,
    selectedDivisions,
    i18n.language,
    filterConditionContextValue,
    deckRankingTabValue,
  ]);

  let content;
  if (error) {
    content = (
      <Typography variant="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    content = (
      <>
        <Grid container rowGap={2}>
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 0 }}>
            <TableComponent items={items} handleTabChange={handleTabChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartComponent
              loading={loading}
              items={items}
              earliestDate={earliestDate}
              latestDate={latestDate}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  return <div>{content}</div>;
}
