import { useState } from "react";

import { ThemeProvider } from "@emotion/react";

import { Stack, createTheme } from "@mui/material";

import FilterCondition from "../../HomePage/components/FilterCondition";
import { FilterConditionProvider } from "../../HomePage/components/FilterConditionContext";
import Stats from "../../HomePage/components/Stats";
import FigureComponent from "./FigureComponent";

export default function RankingFigureTab() {
  const [stats, setStats] = useState("");

  return (
    <ThemeProvider
      theme={createTheme()} // FilterConditionで必要
    >
      <FilterConditionProvider>
        <Stack gap={2}>
          <FilterCondition setStats={setStats} />
          <Stats stats={stats} />
          <FigureComponent
            earliestDate={stats.earliest_date}
            latestDate={stats.latest_date}
            eventNum={stats.event_num}
          />
        </Stack>
      </FilterConditionProvider>
    </ThemeProvider>
  );
}
