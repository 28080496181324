import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Box,
  FormControlLabel,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { pink } from "@mui/material/colors";

import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";
import "../i18n";

export default function TableComponent({
  mainDeckType,
  subDeckType,
  items,
  handleTabChange,
  currentCardName,
}) {
  const { t } = useTranslation(["home_page"]);
  const {
    setMainDeckType,
    setSubDeckType,
    setCardNameQueryList,
    setTriggerSearch,
  } = useContext(SearchDeckTabContext);

  const [showAll, setShowAll] = useState(false);

  const toggleFullDisplay = () => {
    setShowAll((prev) => !prev);
  };

  const processedItems = showAll
    ? items
    : items.reduce((acc, item, index, array) => {
        if (
          (index === 0 && array.length > 1 && array[1].rate !== "100.0") || // 「1枚以上」が100%でない場合のみ「0枚」を追加
          (0 < index &&
            index < array.length - 1 &&
            item.result_num !== array[index + 1].result_num) || // 次の要素と異なるresult_numのとき追加
          index === array.length - 1 // 最後の要素は常に追加
        ) {
          acc.push(item);
        }
        return acc;
      }, []);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <FormControlLabel
          control={<Switch checked={showAll} onChange={toggleFullDisplay} />}
          label={t("card_usage_tab.show_all")}
          sx={{ marginRight: 0 }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow key="Head">
              <TableCell sx={{ maxWidth: 300 }}>
                {t("card_usage_tab.usage")}
              </TableCell>
              <TableCell sx={{ paddingLeft: 0 }}>
                {t("card_usage_tab.rate")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {processedItems.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{ maxWidth: 300 }}>
                  <Link
                    onClick={() => {
                      let card_num = row.label
                        .replace("枚", "")
                        .replace("以上", "");
                      setMainDeckType(mainDeckType);
                      if (subDeckType === "None") {
                        setSubDeckType("指定なし");
                      } else if (subDeckType.includes("その他")) {
                        setSubDeckType("その他");
                      } else {
                        setSubDeckType(subDeckType);
                      }
                      setCardNameQueryList([
                        {
                          card_names: [currentCardName],
                          card_num: card_num,
                          condition: row.label.includes("以上")
                            ? "以上"
                            : "ちょうど",
                        },
                      ]);
                      setTriggerSearch(true);
                      handleTabChange("4");
                    }}
                  >
                    {row.label
                      .replace("枚", t("card_usage_tab.counter_word"))
                      .replace("以上", t("card_usage_tab.or_more"))}
                  </Link>
                </TableCell>
                <TableCell sx={{ paddingLeft: 0, position: "relative" }}>
                  {row.rate}%
                  <LinearProgress
                    variant="determinate"
                    value={Number(row.rate)}
                    sx={{
                      height: 5,
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      backgroundColor: pink[100],
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: pink[600],
                        borderRadius: 5,
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
