import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import { FilterConditionContext } from "../components/FilterConditionContext";
import SearchDeckFormComponent from "../components/SearchDeckFormComponent";
import "../i18n";
import i18n from "../i18n";
import DisplayCategory from "./DisplayCategory";
import GraphComponent from "./GraphComponent";
import { SearchDeckTabContext } from "./SearchDeckTabContext";

export default function SearchDeckTab() {
  const { t } = useTranslation(["home_page"]);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    mainDeckType,
    subDeckType,
    cardNameQueryList,
    items,
    setItems,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    currentSelectedDivisions,
    setCurrentSelectedDivisions,
    triggerSearch,
    setTriggerSearch,
  } = useContext(SearchDeckTabContext);
  const { startDate, endDate, dateRangeError, selectedDivisions } = useContext(
    FilterConditionContext,
  );

  const setParams = useCallback(() => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        if (mainDeckType !== "指定なし")
          newParams.set("main_deck_type", mainDeckType);
        if (subDeckType !== "指定なし")
          newParams.set("sub_deck_type", subDeckType);
        if (
          JSON.stringify(cardNameQueryList) !==
          JSON.stringify([{ card_names: [], card_num: 1, condition: "以上" }])
        )
          newParams.set(
            "card_name_query_list",
            JSON.stringify(cardNameQueryList),
          );
        return newParams;
      },
      { replace: true },
    );
  }, [setSearchParams, mainDeckType, subDeckType, cardNameQueryList]);

  // 検索条件が変わった際にリセット
  useEffect(() => {
    if (dateRangeError) return;
    if (
      // startDateが存在 -> currentStartDateと比較
      // startDateがnull -> currentStartDateもnullならtrue
      (startDate ? startDate.isSame(currentStartDate) : !currentStartDate) &&
      (endDate ? endDate.isSame(currentEndDate) : !currentEndDate) &&
      selectedDivisions === currentSelectedDivisions
    ) {
      setParams();
      return;
    }

    setItems({
      stats: [],
      decks_by_category: [],
      time_history: [],
    });
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    setCurrentSelectedDivisions(selectedDivisions);
  }, [
    startDate,
    currentStartDate,
    setCurrentStartDate,
    endDate,
    currentEndDate,
    setCurrentEndDate,
    dateRangeError,
    selectedDivisions,
    currentSelectedDivisions,
    setCurrentSelectedDivisions,
    setParams,
    setItems,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (dateRangeError) return;

      setLoading(true);

      try {
        const params = new URLSearchParams();
        if (startDate) params.append("start_date", startDate.toISOString());
        if (endDate) params.append("end_date", endDate.toISOString());
        if (mainDeckType && mainDeckType !== "指定なし")
          params.append("main_deck_type", mainDeckType);
        if (subDeckType && subDeckType !== "指定なし")
          params.append("sub_deck_type", subDeckType);
        params.append(
          "card_name_query_list",
          JSON.stringify(cardNameQueryList),
        );
        params.append("divisions", JSON.stringify(selectedDivisions));
        params.append("language", i18n.language);

        const url =
          `${process.env.REACT_APP_API_URL}/search_deck` +
          (params.toString() ? `?${params}` : "");

        const response = await fetch(url);
        if (!response.ok) {
          const errorBody = await response.json();
          setError(
            "ERROR: " + errorBody.detail ||
              `API returned status ${response.status}`,
          );
          setItems({
            stats: {},
            decks_by_category: [],
          });
        } else {
          setParams();

          setError(null);
          const data = await response.json();
          setItems(data);
        }
      } catch (e) {
        console.error("An unexpected error occured: ", e);
      } finally {
        setLoading(false);
        setTriggerSearch(false);
      }
    };

    if (triggerSearch) {
      fetchData();
    }
  }, [
    triggerSearch,
    setTriggerSearch,
    dateRangeError,
    mainDeckType,
    subDeckType,
    cardNameQueryList,
    startDate,
    endDate,
    selectedDivisions,
    setParams,
    setItems,
  ]);

  let content;
  if (error) {
    content = (
      <Typography varient="body1" color={"error"} marginY={2}>
        {error}
      </Typography>
    );
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }} width="100%">
        <CircularProgress />
      </Box>
    );
  } else {
    content = items.decks_by_category.length > 0 && (
      <Stack rowGap={3}>
        <Box>
          <GraphComponent data={items.time_history} />
        </Box>
        {items.is_result_num_clipped && (
          <Alert severity="info">
            {t("search_deck_tab.too_many_deck_alert")}
          </Alert>
        )}
        <Stack spacing={2}>
          {items.decks_by_category.map((item, index) => (
            <DisplayCategory
              item={item}
              key={index}
              customKey={index} // keyはReact内部の識別子として使われるので、コンポーネントのpropsには自動的には渡されないため、customKeyという別の名前にしている
            />
          ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <Box
        display="flex"
        marginBottom={2}
        gap={2}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box flexGrow={1}>
          <SearchDeckFormComponent
            startDate={startDate}
            endDate={endDate}
            context={SearchDeckTabContext}
          />
        </Box>
        <Button
          variant="contained"
          color="button"
          sx={{
            minWidth: 0,
            paddingX: 1,
          }}
          onClick={() => {
            setTriggerSearch(true);
          }}
        >
          <SearchIcon />
        </Button>
      </Box>
      {!useMediaQuery("(max-width:600px)") && (
        <Divider sx={{ marginBottom: 3 }} color={blueGrey[200]} />
      )}
      {content}
    </>
  );
}
