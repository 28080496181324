import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { Box, Typography } from "@mui/material";
import { blueGrey, deepOrange } from "@mui/material/colors";

import "../i18n";
import i18n from "../i18n";

export default function GraphComponent({ data }) {
  const { t } = useTranslation(["home_page"]);
  const isXs = useMediaQuery("(max-width:600px)");
  const [hoveredDataList, setHoveredDataList] = useState([null, null]);
  const [showHoveredData, setShowHoveredData] = useState([false, false]);
  const [radioValue, setRadioValue] = useState("top16");

  const charts = [
    {
      title:
        i18n.language === "ja"
          ? `${radioValue === "top8" ? "トップ8" : "トップ16"}に対する割合`
          : `Percentage within ${radioValue === "top8" ? "Top 8" : "Top 16"}`,
      component: (
        <ResponsiveContainer width="100%" height={200}>
          <ComposedChart
            data={radioValue === "top16" ? data.top16 : data.top8}
            margin={{ top: 20, right: 20 }}
            onMouseMove={(e) => {
              if (e && e.activePayload) {
                setHoveredDataList([
                  e.activePayload[0].payload,
                  hoveredDataList[1],
                ]);
                setShowHoveredData([true, showHoveredData[1]]);
              }
            }}
            onMouseLeave={() => {
              setShowHoveredData([false, showHoveredData[1]]);
              setTimeout(() => {
                setHoveredDataList([null, hoveredDataList[1]]);
              }, 300); // タイムアウトしないと即座に表要素が消されてCollapseによる縮小アニメーションが無くなってしまう
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="date" tick={{ fontSize: 14 }} />
            <YAxis
              unit="%"
              width={70} //  小数点用に広げる（本当は自動調整したいけど現状その機能がないっぽい)
            />
            <Line
              dataKey="deck_type_ratio_all"
              stroke={deepOrange[600]}
              strokeWidth={2}
              legendType="none"
              connectNulls={true}
              animationDuration={700}
              strokeDasharray="5 5"
              dot={false}
            />
            <Line
              dataKey="deck_type_ratio_all"
              stroke={deepOrange[600]}
              strokeWidth={2}
              name={t("search_deck_tab.graph1_label1")}
              animationDuration={700}
              dot={false}
            />
            <Line
              dataKey="search_match_ratio_all"
              stroke={blueGrey[600]}
              strokeWidth={2}
              legendType="none"
              connectNulls={true}
              animationDuration={700}
              strokeDasharray="5 5"
              dot={false}
            />
            <Line
              dataKey="search_match_ratio_all"
              stroke={blueGrey[600]}
              strokeWidth={2}
              name={t("search_deck_tab.graph1_label2")}
              animationDuration={700}
              dot={false}
            />
            <Tooltip content={<></>} />
            <Legend
              align="right"
              wrapperStyle={{ fontSize: "12px", right: 0 }}
              margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
              style={{ marginRight: 0 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ),
      hoveredData: hoveredDataList[0],
    },
    {
      title:
        i18n.language === "ja"
          ? `検索条件一致 / 検索デッキタイプ一致（${radioValue === "top8" ? "トップ8" : "トップ16"}）`
          : `Search Match / Deck Type Match (${radioValue === "top8" ? "Top 8" : "Top 16"})`,
      component: (
        <ResponsiveContainer width="100%" height={200}>
          <ComposedChart
            data={radioValue === "top16" ? data.top16 : data.top8}
            margin={{ top: 20 }}
            barSize="5%"
            onMouseMove={(e) => {
              if (e && e.activePayload) {
                setHoveredDataList([
                  hoveredDataList[0],
                  e.activePayload[0].payload,
                ]);
                setShowHoveredData([showHoveredData[0], true]);
              }
            }}
            onMouseLeave={() => {
              setShowHoveredData([showHoveredData[0], false]);
              setTimeout(() => {
                setHoveredDataList([hoveredDataList[0], null]);
              }, 300); // タイムアウトしないと即座に表要素が消されてCollapseによる縮小アニメーションが無くなってしまう
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <Bar
              dataKey="search_match_ratio_deck"
              fill={blueGrey[300]}
              background={({ search_match_ratio_deck, x, y, width, height }) =>
                search_match_ratio_deck != null && (
                  <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill="#eeeeee"
                  />
                )
              }
              legendType="none"
              animationDuration={700}
            />
            <XAxis dataKey="date" tick={{ fontSize: 14 }} />
            <YAxis domain={[0, 100]} unit="%" width={50} />
            <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
            <Tooltip content={<></>} />
          </ComposedChart>
        </ResponsiveContainer>
      ),
      hoveredData: hoveredDataList[1],
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="flex-end" marginBottom={1}>
        <FormControl>
          <RadioGroup
            row
            value={radioValue}
            onChange={(event) => {
              setRadioValue(event.target.value);
            }}
          >
            <FormControlLabel
              value="top8"
              control={<Radio />}
              label={t("search_deck_tab.top8")}
            />
            <FormControlLabel
              value="top16"
              control={<Radio />}
              label={t("search_deck_tab.top16")}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={10}
          slidesPerView={isXs ? 1 : 2}
          allowTouchMove={false} // モバイルだとグラフのツールチップが長押しスライドじゃないと表示できないのでスワイプは無くす
        >
          {charts.map((item, index) => (
            <SwiperSlide key={index}>
              <Box
                paddingX={isXs ? 2 : 0} // navigation用のスペース
              >
                <Typography variant="body1" align="center">
                  {item.title}
                </Typography>
                {item.component}
              </Box>
              <Box display="flex" justifyContent="center">
                <Collapse in={showHoveredData[index]} timeout={100}>
                  <Box>
                    {item.hoveredData && (
                      <Stack alignItems="center" marginTop={2}>
                        {item.hoveredData.date}
                        <TableContainer
                          sx={{
                            minWidth: i18n.language === "ja" ? 280 : 310,
                            width: "fit-content",
                          }}
                        >
                          <Table size="small">
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell sx={{ paddingRight: 0 }}>
                                  {t("search_deck_tab.graph_table1")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ paddingRight: 0 }}
                                >
                                  {item.hoveredData.total_search_match_on_date}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ paddingLeft: 0 }}
                                >
                                  (
                                  {item.hoveredData.total_search_match_on_date >
                                  0
                                    ? (
                                        (item.hoveredData
                                          .total_search_match_on_date /
                                          (index === 0
                                            ? item.hoveredData
                                                .total_events_on_date
                                            : item.hoveredData
                                                .total_deck_type_on_date)) *
                                        100
                                      ).toFixed(1)
                                    : " - "}
                                  %)
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell sx={{ paddingRight: 0 }}>
                                  {t("search_deck_tab.graph_table2")}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ paddingRight: 0 }}
                                >
                                  {item.hoveredData.total_deck_type_on_date}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  sx={{ paddingLeft: 0 }}
                                >
                                  {index === 0 &&
                                    `(${
                                      item.hoveredData.total_deck_type_on_date >
                                      0
                                        ? (
                                            (item.hoveredData
                                              .total_deck_type_on_date /
                                              item.hoveredData
                                                .total_events_on_date) *
                                            100
                                          ).toFixed(1)
                                        : " - "
                                    }%)`}
                                </TableCell>
                              </TableRow>
                              {index === 0 && (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell sx={{ paddingRight: 0 }}>
                                    {t("search_deck_tab.graph_table3")}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ paddingRight: 0 }}
                                  >
                                    {item.hoveredData.total_events_on_date}
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    )}
                  </Box>
                </Collapse>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <style>
        {`
          .swiper-button-prev,
          .swiper-button-next {
            height: 30px;
          }
          .swiper-button-prev {
            left: 0;
            justify-content: flex-start;
          }
          .swiper-button-next {
            right: 0;
            justify-content: flex-end;
          }
          .swiper-button-prev::after,
          .swiper-button-next::after {
            font-size: 20px;
          }
        `}
      </style>
    </>
  );
}
