import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import { FilterConditionContext } from "../components/FilterConditionContext";
import SearchDeckFormComponent from "../components/SearchDeckFormComponent";
import "../i18n";
import i18n from "../i18n";
import { CardUsageTabContext } from "./CardUsageTabContext";
import TableComponent from "./TableComponent";

export default function CardUsageTab({ handleTabChange }) {
  const { t } = useTranslation(["home_page"]);
  const [, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [cardOptions, setCardOptions] = useState([]);
  const {
    error,
    setError,
    items,
    setItems,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    currentSelectedDivisions,
    setCurrentSelectedDivisions,
    cardName,
    setCardName,
    currentCardName,
    setCurrentCardName,
    open,
    setOpen,
    mainDeckType,
    subDeckType,
    cardNameQueryList,
    isBeforeSearch,
    setIsBeforeSearch,
  } = useContext(CardUsageTabContext);
  const { startDate, endDate, dateRangeError, selectedDivisions } = useContext(
    FilterConditionContext,
  );

  useEffect(() => {
    const fetchCardNames = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/cards?search=${encodeURIComponent(cardName)}&language=${i18n.language}`,
        );
        const data = await response.json();
        setCardOptions(data);
      } catch (error) {
        console.error("Failed to fetch card names:", error);
      }
    };

    if (cardName.length > 0) {
      fetchCardNames();
    }
  }, [cardName]);

  const setParams = useCallback(() => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        if (cardName) newParams.set("card_name", cardName);
        if (mainDeckType !== "指定なし")
          newParams.set("main_deck_type", mainDeckType);
        if (subDeckType !== "指定なし")
          newParams.set("sub_deck_type", subDeckType);
        if (
          JSON.stringify(cardNameQueryList) !==
          JSON.stringify([{ card_names: [], card_num: 1, condition: "以上" }])
        )
          newParams.set(
            "card_name_query_list",
            JSON.stringify(cardNameQueryList),
          );
        if (open) newParams.set("open", open.toString());
        return newParams;
      },
      { replace: true },
    );
  }, [
    setSearchParams,
    cardName,
    mainDeckType,
    subDeckType,
    cardNameQueryList,
    open,
  ]);

  // 検索条件が変わったときにデータをリセット
  useEffect(() => {
    if (dateRangeError) return;

    if (
      // startDateが存在 -> currentStartDateと比較
      // startDateがnull -> currentStartDateもnullならtrue
      (startDate ? startDate.isSame(currentStartDate) : !currentStartDate) &&
      (endDate ? endDate.isSame(currentEndDate) : !currentEndDate) &&
      selectedDivisions === currentSelectedDivisions
    ) {
      setParams();
      return;
    }

    setItems([]);
    setIsBeforeSearch(true);
    setCurrentStartDate(startDate);
    setCurrentEndDate(endDate);
    setCurrentSelectedDivisions(selectedDivisions);
  }, [
    startDate,
    currentStartDate,
    setCurrentStartDate,
    endDate,
    currentEndDate,
    setCurrentEndDate,
    dateRangeError,
    selectedDivisions,
    currentSelectedDivisions,
    setCurrentSelectedDivisions,
    setParams,
    setItems,
    setIsBeforeSearch,
  ]);

  const fetchData = async () => {
    if (dateRangeError) return;

    setLoading(true);

    try {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate.toISOString());
      if (endDate) params.append("end_date", endDate.toISOString());
      if (cardName) params.append("card_name", cardName);
      if (mainDeckType && mainDeckType !== "指定なし")
        params.append("main_deck_type", mainDeckType);
      if (subDeckType && subDeckType !== "指定なし")
        params.append("sub_deck_type", subDeckType);
      params.append("card_name_query_list", JSON.stringify(cardNameQueryList));
      params.append("language", i18n.language);
      params.append("divisions", JSON.stringify(selectedDivisions));

      const url =
        `${process.env.REACT_APP_API_URL}/card_usage` +
        (params.toString() ? `?${params}` : "");

      const response = await fetch(url);
      if (!response.ok) {
        const errorBody = await response.json();
        setError(
          "ERROR: " + errorBody.detail ||
            `API returned status ${response.status}`,
        );
      } else {
        setParams();

        const data = await response.json();
        setItems(data);
        setError(false);
        setIsBeforeSearch(false);
      }
    } catch (e) {
      // ネットワークエラーなどの問題
      console.error("An unexpected error occurred:", e);
    } finally {
      setLoading(false);
    }
  };

  let content;
  if (error) {
    if (error === "ERROR: card_nameを指定してください") {
      content = (
        <Typography variant="body1" color={"error"} marginY={2}>
          {t("card_usage_tab.no_card_name_error")}
        </Typography>
      );
    } else {
      content = (
        <Typography variant="body1" color={"error"} marginY={2}>
          {error}
        </Typography>
      );
    }
  } else if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } else if (isBeforeSearch) {
    content = null;
  } else if (items.length === 0) {
    content = <p>No data found.</p>;
  } else {
    const filteredItems = items.filter((item) => item.total_result_num !== 0); //result_numが0のものを除外
    if (filteredItems.length === 0) {
      content = <p>No data found.</p>;
    } else {
      content = (
        <>
          <Grid container spacing={4}>
            {filteredItems.map((item, index) => (
            //{items.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Typography variant="h6">
                  {item.sub_deck_type !== "None"
                    ? `${item.main_deck_type} / ${item.sub_deck_type}`
                    : item.main_deck_type}
                </Typography>
                <Divider
                  color={blueGrey[600]}
                  sx={{ height: 2, marginBottom: 1 }}
                />
                <Typography
                  variant="body1"
                  marginBottom={1}
                  textAlign="right"
                  color={blueGrey[800]}
                >
                  {t("card_usage_tab.result_num")}: {item.total_result_num}
                </Typography>
                <Box width="100%" display="flex" justifyContent="center">
                  <Box maxWidth="80%">
                    <TableComponent
                      mainDeckType={item.main_deck_type}
                      subDeckType={item.sub_deck_type}
                      items={item.usage_rates}
                      handleTabChange={handleTabChange}
                      currentCardName={currentCardName}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          marginBottom: { xs: 4, sm: 2 },
        }}
        gap={2}
      >
        <Stack spacing={1.5} flexGrow={1}>
          <Autocomplete
            options={cardOptions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name || ""
            }
            onInputChange={(event, newValue) => {
              setCardName(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Card Name" />
            )}
            value={cardName}
            sx={{ maxWidth: { xs: "100%", sm: 300 } }}
          />
          <Box>
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)} sx={{ paddingLeft: 0 }}>
                <ArrowRightIcon
                  sx={{
                    transform: open ? "rotate(90deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                    color: "grey",
                  }}
                />
                <Typography
                  color="black"
                  variant="body1"
                  sx={{ textTransform: "None" }}
                >
                  {t("card_usage_tab.advanced_search")}
                </Typography>
              </Button>
              <Box flexGrow={1}>
                <Divider />
              </Box>
            </Box>
            <Collapse in={open}>
              <Box marginTop={1}>
                <SearchDeckFormComponent
                  startDate={startDate}
                  endDate={endDate}
                  context={CardUsageTabContext}
                />
              </Box>
            </Collapse>
          </Box>
        </Stack>
        <Button
          variant="contained"
          color="button"
          sx={{
            minWidth: 0,
            paddingX: 1,
          }}
          onClick={() => {
            fetchData();
            setCurrentCardName(cardName);
          }}
        >
          <SearchIcon />
        </Button>
      </Box>
      {!useMediaQuery("(max-width:600px)") && (
        <Divider sx={{ marginBottom: 3 }} color={blueGrey[200]} />
      )}
      {content}
    </>
  );
}
