import i18n from "./i18n";

export const fetchData = async (
  filterConditionValue,
  tabContextValue,
  setLoading,
  endPoint,
) => {
  const { startDate, endDate, dateRangeError, selectedDivisions } =
    filterConditionValue;
  const {
    setItems,
    setError,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    currentSelectedDivisions,
    setCurrentSelectedDivisions,
    currentLanguage,
    setCurrentLanguage,
  } = tabContextValue;

  if (dateRangeError) return;
  if (
    (startDate ? startDate.isSame(currentStartDate) : !currentStartDate) &&
    (endDate ? endDate.isSame(currentEndDate) : !currentEndDate) &&
    selectedDivisions === currentSelectedDivisions &&
    i18n.language === currentLanguage
  )
    return;

  setLoading(true);

  try {
    const params = new URLSearchParams();
    if (startDate) params.append("start_date", startDate.toISOString());
    if (endDate) params.append("end_date", endDate.toISOString());
    params.append("divisions", JSON.stringify(selectedDivisions));
    params.append("language", i18n.language);
    const url =
      `${process.env.REACT_APP_API_URL}/${endPoint}` +
      (params.toString() ? `?${params.toString()}` : "");

    const response = await fetch(url);
    if (!response.ok) {
      const errorBody = await response.json();
      setError(
        "ERROR: " + errorBody.detail ||
          `API returned status ${response.status}`,
      );
    } else {
      const data = await response.json();
      setItems(data);
      setError(false);
    }
  } catch (e) {
    // ネットワークエラーなどの問題
    console.error("An unexpected error occurred:", e);
  } finally {
    setLoading(false);
  }

  setCurrentStartDate(startDate);
  setCurrentEndDate(endDate);
  setCurrentSelectedDivisions(selectedDivisions);
  setCurrentLanguage(i18n.language);
};
