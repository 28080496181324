import React, { useEffect, useRef, useState } from "react";

import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  EffectCoverflow,
  Navigation,
  Pagination,
  Grid as SwiperGrid,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid as MUIGrid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import "../i18n";

export default function DisplayCategory({ item, customKey }) {
  const { t } = useTranslation(["home_page"]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  const [cardHeight, setCardHeight] = useState(null);
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setCardHeight(entry.contentRect.height + 3); // 3はボーダー用
        }
      });

      observer.observe(cardRef.current);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <>
      <Typography
        variant="h6"
        style={{
          paddingLeft: "8px",
          borderLeft: `5px solid #FE6B8B`,
          fontWeight: "bold",
        }}
      >
        {item.category}
      </Typography>
      <Box
        position="relative" // paginationの位置を下端に揃える用
        paddingBottom={5}
      >
        <Box
          position="relative" // buttonの位置を中央に揃える用
        >
          <Swiper
            modules={[EffectCoverflow, SwiperGrid, Pagination, Navigation]}
            effect={isSmallScreen && "coverflow"}
            slidesPerView={isSmallScreen ? 1.2 : 3}
            centeredSlides={isSmallScreen}
            loop={!isSmallScreen}
            grid={{ rows: isSmallScreen ? 2 : 1 }}
            spaceBetween={10}
            coverflowEffect={{
              rotate: isSmallScreen ? -30 : -15,
              stretch: isSmallScreen ? 15 : 0,
            }}
            grabCursor={true}
            navigation={{
              enabled: !isMobile,
              prevEl: `#button_prev_${customKey}`, // button_prev_{index}というIDを持つdiv要素が戻るボタンとして動作するようになる。こうすることで戻るボタンをSwiperの外に配置して重なりを防ぐことが可能
              nextEl: `#button_next_${customKey}`,
            }}
            pagination={{
              clickable: true,
              el: `#pagination_${customKey}`,
              dynamicBullets: true,
              dynamicMainBullets: 5,
              renderBullet: function (index, className) {
                return `<span class="${className}">${index + 1}</span>`;
              },
            }}
            style={{
              marginLeft: !isMobile ? 21 : 0,
              marginRight: !isMobile ? 21 : 0,
              height:
                isSmallScreen && item.decks.length > 1
                  ? cardHeight * 2 + 10
                  : cardHeight, // Gridのために高さを指定しておく必要がある
            }}
          >
            {item.decks.map((deck, index) => (
              <SwiperSlide key={index}>
                <Card
                  elevation={2}
                  sx={{ border: "1px solid #bdbdbd" }}
                  ref={index === 0 ? cardRef : null}
                >
                  <Box position="relative" sx={{ aspectRatio: 2 }}>
                    <Link
                      to={deck.deck_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CardMedia
                        component="img"
                        image={deck.thumbnail}
                        referrerPolicy="no-referrer"
                        loading="lazy"
                      />
                    </Link>
                    <Box
                      position="absolute"
                      top={0}
                      right={0}
                      sx={{ padding: "4px" }}
                    >
                      <LaunchIcon sx={{ fontSize: 20, color: "black" }} />
                    </Box>
                  </Box>
                  <CardContent style={{ padding: "8px 8px 4px 8px" }}>
                    <MUIGrid container>
                      <MUIGrid item xs={7}>
                        <Typography variant="body1">
                          {deck.main_type} <br />
                          {deck.sub_type !== "None" && deck.sub_type} <br />
                          <Link
                            to={deck.deck_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("search_deck_tab.deck_detail")}
                          </Link>
                        </Typography>
                      </MUIGrid>
                      <MUIGrid item xs={5}>
                        <Typography variant="body1" align="right">
                          {deck.date} <br />
                          {item.category} <br />
                          <Link
                            to={deck.event_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t("search_deck_tab.event_detail")}
                          </Link>
                        </Typography>
                      </MUIGrid>
                    </MUIGrid>
                  </CardContent>
                </Card>
                <div className="swiper-lazy-preloader"></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            id={`button_prev_${customKey}`}
            className="swiper-button-prev"
            style={{ display: isMobile && "none" }}
          />
          <div
            id={`button_next_${customKey}`}
            className="swiper-button-next"
            style={{ display: isMobile && "none" }}
          />
        </Box>
        <div
          id={`pagination_${customKey}`}
          className="swiper-pagination"
          style={{ bottom: "0px" }}
        />
      </Box>
      <style>
        {`
        .swiper-pagination-bullet {
          width: 25px;
          height: 25px;
          text-align: center;
          line-height: 27px;
          font-size: 12px;
          color: #000;
          opacity: 1;
          background: rgba(0, 0, 0, 0.1);
        }

        .swiper-pagination-bullet-active {
          color: #fff;
          background: #007aff;
        }
          `}
      </style>
    </>
  );
}
