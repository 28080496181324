import React from "react";

import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Card,
  CardMedia,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

function SortableTableRow({ deck }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: deck.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : "auto",
    position: isDragging ? "relative" : "static",
    backgroundColor: isDragging ? "rgba(255, 255, 255, 0.9)" : "inherit",
    boxShadow: isDragging ? "0px 4px 10px rgba(0, 0, 0, 0.2)" : "none",
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell>{deck.name_ja}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={0.5} padding={0}>
          {deck.thumbnails &&
            deck.thumbnails.map((thumbnail, index) => (
              <Card
                sx={{
                  border: "1px solid #bdbdbd",
                  width: { xs: "50px", sm: "50%" },
                  maxWidth: "80px",
                }}
                key={index}
              >
                <CardMedia
                  component="img"
                  image={thumbnail}
                  alt={deck.name_ja}
                  style={{
                    transform: "translateY(-5%)",
                    aspectRatio: "1.35/1",
                    objectPosition: "top",
                    marginBottom: "-5%",
                  }}
                  referrerPolicy="no-referrer"
                />
              </Card>
            ))}
        </Stack>
      </TableCell>
      <TableCell>
        <IconButton
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
          size="small"
          style={{ touchAction: "none" }}
        >
          <DragIndicatorIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default function DeckTable({ deckList, setDeckList }) {
  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setDeckList((deckList) => {
        const oldIndex = deckList.findIndex((item) => item.id === active.id);
        const newIndex = deckList.findIndex((item) => item.id === over.id);
        return arrayMove(deckList, oldIndex, newIndex);
      });
    }
  }

  return (
    <TableContainer component={Paper} elevation={2}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={deckList.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table size="small">
            <TableBody>
              {deckList.map((deck) => (
                <SortableTableRow key={deck.id} deck={deck} />
              ))}
            </TableBody>
          </Table>
        </SortableContext>
      </DndContext>
    </TableContainer>
  );
}
