import { useState } from "react";
import { createContext } from "react";

import { useSearchParams } from "react-router-dom";

export const CardUsageTabContext = createContext(null);

export function CardUsageTabProvider({ children }) {
  const [searchParams] = useSearchParams();

  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [currentSelectedDivisions, setCurrentSelectedDivisions] =
    useState(null);

  const [cardName, setCardName] = useState(searchParams.get("card_name") || []);
  const [currentCardName, setCurrentCardName] = useState("");
  const [open, setOpen] = useState(searchParams.get("open") === "true");
  const [mainDeckType, setMainDeckType] = useState(
    searchParams.get("main_deck_type") || "指定なし",
  );
  const [subDeckType, setSubDeckType] = useState(
    searchParams.get("sub_deck_type") || "指定なし",
  );
  const [cardNameQueryList, setCardNameQueryList] = useState(
    searchParams.get("card_name_qury_list")
      ? JSON.parse(searchParams.get("card_name_query_list"))
      : [{ card_names: [], card_num: 1, condition: "以上" }],
  );
  const [isBeforeSearch, setIsBeforeSearch] = useState(true);

  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  return (
    <CardUsageTabContext.Provider
      value={{
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        currentSelectedDivisions,
        setCurrentSelectedDivisions,
        cardName,
        setCardName,
        currentCardName,
        setCurrentCardName,
        open,
        setOpen,
        mainDeckType,
        setMainDeckType,
        subDeckType,
        setSubDeckType,
        cardNameQueryList,
        setCardNameQueryList,
        isBeforeSearch,
        setIsBeforeSearch,
        items,
        setItems,
        error,
        setError,
      }}
    >
      {children}
    </CardUsageTabContext.Provider>
  );
}
