import { createContext } from "react";
import { useState } from "react";

import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

export const FilterConditionContext = createContext(null);

export function FilterConditionProvider({ children }) {
  const [searchParams] = useSearchParams();

  const urlStartDate = searchParams.get("start_date");
  const urlEndDate = searchParams.get("end_date");
  const [startDate, setStartDate] = useState(
    urlStartDate ? dayjs.utc(urlStartDate) : dayjs.utc("2025-03-14"),
  );
  const [endDate, setEndDate] = useState(
    urlEndDate ? dayjs.utc(urlEndDate) : null,
  );
  const [selectedDivisions, setSelectedDivisions] = useState(
    searchParams.get("divisions")
      ? JSON.parse(searchParams.get("divisions"))
      : ["open", "master", "senior", "junior"],
  );
  const [dateRangeError, setDateRangeError] = useState("");

  return (
    <FilterConditionContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        dateRangeError,
        setDateRangeError,
        selectedDivisions,
        setSelectedDivisions,
      }}
    >
      {children}
    </FilterConditionContext.Provider>
  );
}
