import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import LaunchIcon from "@mui/icons-material/Launch";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";
import { fetchData } from "../apiService";
import { FilterConditionContext } from "../components/FilterConditionContext";
import "../i18n";
import { AceSpecTabContext } from "./AceSpecTabContext";

function AceSpecDisplay({
  aceSpecs,
  handleTabChange,
  mainDeckType,
  subDeckType,
}) {
  const isXs = useMediaQuery("(max-width:600px)");
  const cardWidth = isXs ? 50 : 70;
  const barSize = isXs ? 10 : 15;
  const {
    setMainDeckType,
    setSubDeckType,
    setCardNameQueryList,
    setTriggerSearch,
  } = useContext(SearchDeckTabContext);

  const CustomLabel = (props) => {
    const { x, y, height, value, name } = props;
    return (
      <foreignObject
        x={x - cardWidth - 5}
        y={y - ((cardWidth / 1.35) * 0.85) / 2 + barSize / 2}
        width={cardWidth}
        height={height}
        style={{ overflow: "visible" }}
      >
        <Card>
          <CardActionArea
            onClick={() => {
              if (name !== "エーススペックなし") {
                setMainDeckType(mainDeckType);
                if (subDeckType === "None") {
                  setSubDeckType("指定なし");
                } else if (
                  subDeckType.includes("その他") ||
                  subDeckType.includes("Other")
                ) {
                  setSubDeckType("その他");
                } else {
                  setSubDeckType(subDeckType);
                }
                setCardNameQueryList([
                  {
                    card_names: [name],
                    card_num: 1,
                    condition: "ちょうど",
                  },
                ]);
                setTriggerSearch(true);
                handleTabChange("4");
              }
            }}
          >
            <Box position="relative" height={(cardWidth / 1.35) * 0.85}>
              {name === "エーススペックなし" ? (
                <Box
                  sx={{
                    width: cardWidth,
                    height: (cardWidth / 1.35) * 0.85,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f0f0f0",
                    color: "#333",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    padding: 0,
                  }}
                >
                  No
                  <br />
                  Ace Spec
                </Box>
              ) : (
                <CardMedia
                  component="img"
                  image={value}
                  style={{
                    transform: "translateY(-15%)",
                    aspectRatio: "1.35/1",
                    objectPosition: "top",
                  }}
                  referrerPolicy="no-referrer"
                />
              )}
              <Box
                position="absolute"
                top={0}
                right={0}
                sx={{ padding: "1px" }}
              >
                <LaunchIcon sx={{ fontSize: 10, color: "white" }} />
              </Box>
            </Box>
          </CardActionArea>
        </Card>
      </foreignObject>
    );
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={
        aceSpecs.length * ((cardWidth / 1.35) * 0.85) + // カード高さ
        (aceSpecs.length - 1) * 10 + // スペーシング
        30 // X軸
      }
    >
      <BarChart data={aceSpecs} margin={{ right: 60 }} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
        />
        <YAxis
          type="category"
          dataKey="name"
          tick={false}
          width={cardWidth + 15}
        />
        <Bar
          dataKey="share"
          fill={blueGrey[500]}
          label="false"
          barSize={barSize}
          onMouseOver={(data, index) => {}}
          animationDuration={200}
        >
          <LabelList
            dataKey="thumbnail"
            content={<CustomLabel />}
            position="left"
          />
          <LabelList
            dataKey="share"
            position="right"
            formatter={(value) => `${value}%`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default function AceSpecTab({ handleTabChange }) {
  const { t, i18n } = useTranslation(["home_page"]);

  const [loading, setLoading] = useState(false);

  const aceSpecTabValue = useContext(AceSpecTabContext);
  const { error, items } = aceSpecTabValue;
  const filterConditionContextValue = useContext(FilterConditionContext);
  const { startDate, endDate, selectedDivisions } = filterConditionContextValue;

  useEffect(() => {
    fetchData(
      filterConditionContextValue,
      aceSpecTabValue,
      setLoading,
      "ace_spec",
    );
  }, [
    startDate,
    endDate,
    selectedDivisions,
    i18n.language,
    filterConditionContextValue,
    aceSpecTabValue,
  ]);

  if (error) {
    return (
      <Typography variant="body1" color="error" marginY={2}>
        {error}
      </Typography>
    );
  }

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (items.length === 0) {
    return <p>No data found.</p>;
  }

  return (
    <Grid container spacing={3}>
      {items.map((item, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Typography variant="h6">
            {item.sub_deck_type !== "None"
              ? `${item.main_deck_type} / ${item.sub_deck_type}`
              : item.main_deck_type}
          </Typography>
          <Divider color={blueGrey[600]} sx={{ height: 2, marginBottom: 1 }} />
          <Typography
            variant="body1"
            textAlign="right"
            color={blueGrey[800]}
            marginBottom={2}
          >
            {t("ace_spec_tab.result_num")}：{item.result_num}
          </Typography>
          <Grid container spacing={1}>
            <AceSpecDisplay
              aceSpecs={item.ace_specs}
              handleTabChange={handleTabChange}
              mainDeckType={item.main_deck_type}
              subDeckType={item.sub_deck_type}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
