import { useContext, useEffect } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import ClearIcon from "@mui/icons-material/Clear";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "../i18n";
import { FilterConditionContext } from "./FilterConditionContext";

dayjs.extend(utc);

export default function FilterCondition({ setStats }) {
  const { t } = useTranslation(["filter_condition_and_stats"]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateRangeError,
    setDateRangeError,
    selectedDivisions,
    setSelectedDivisions,
  } = useContext(FilterConditionContext);

  const [searchParams, setSearchParams] = useSearchParams();

  // URLから初期日付を取得
  useEffect(() => {
    const urlStartDate = searchParams.get("start_date");
    const urlEndDate = searchParams.get("end_date");

    if (urlStartDate) setStartDate(dayjs.utc(urlStartDate));
    if (urlEndDate) setEndDate(dayjs.utc(urlEndDate));
  }, [searchParams, setStartDate, setEndDate]);

  // ボタン変更時にURLを更新
  useEffect(() => {
    if (dateRangeError) return;

    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        if (startDate) {
          newParams.set("start_date", startDate.format("YYYY-MM-DD"));
        } else {
          newParams.delete("start_date");
        }
        if (endDate) {
          newParams.set("end_date", endDate.format("YYYY-MM-DD"));
        } else {
          newParams.delete("end_date");
        }
        if (selectedDivisions.length > 0) {
          newParams.set("divisions", JSON.stringify(selectedDivisions));
        } else {
          newParams.delete("divisions");
        }
        return newParams;
      },
      { replace: true },
    );
  }, [startDate, endDate, dateRangeError, setSearchParams, selectedDivisions]);

  useEffect(() => {
    if (dateRangeError) return;

    const fetchData = async () => {
      try {
        const params = new URLSearchParams();
        if (startDate) params.append("start_date", startDate.toISOString());
        if (endDate) params.append("end_date", endDate.toISOString());
        params.append("divisions", JSON.stringify(selectedDivisions));

        const url =
          `${process.env.REACT_APP_API_URL}/stats` +
          (params.toString() ? `?${params.toString()}` : "");
        const response = await fetch(url);

        if (response.ok) {
          const data = await response.json();
          setStats(data);
        }
      } catch (e) {
        console.error("An unexpected error occurred:", e);
      }
    };

    fetchData();
  }, [startDate, endDate, dateRangeError, selectedDivisions, setStats]);

  // チェックボックスの変更を処理
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDivisions((prevSelected) => {
      const newSelected = checked
        ? [...prevSelected, value]
        : prevSelected.filter((v) => v !== value);
      return newSelected;
    });
  };

  return (
    <div>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={6} sm={4} md={3}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
                if (endDate && dayjs(endDate).isBefore(dayjs(newValue))) {
                  setDateRangeError(t("date_range_error"));
                } else {
                  setDateRangeError("");
                }
              }}
              timezone="UTC"
              slotProps={{
                field: { clearable: true },
                textField:
                  isMobile && startDate
                    ? {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setStartDate(null);
                                  setDateRangeError("");
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        fullWidth: true,
                      }
                    : { fullWidth: true },
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
                if (startDate && dayjs(newValue).isBefore(dayjs(startDate))) {
                  setDateRangeError(t("date_range_error"));
                } else {
                  setDateRangeError("");
                }
              }}
              timezone="UTC"
              slotProps={{
                field: { clearable: true },
                textField:
                  isMobile && endDate
                    ? {
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEndDate(null);
                                  setDateRangeError("");
                                }}
                                edge="end"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                        fullWidth: true,
                      }
                    : { fullWidth: true },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent={{ xs: "space-between", sm: "flex-start" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                value="open"
                checked={selectedDivisions.includes("open")}
                onChange={handleCheckboxChange}
                color="default"
                size="small"
                sx={{ paddingRight: 0.5, paddingY: 0.5 }}
              />
            }
            label={<Typography variant="body2">{t("open")}</Typography>}
            sx={{ marginRight: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="master"
                checked={selectedDivisions.includes("master")}
                onChange={handleCheckboxChange}
                color="default"
                size="small"
                sx={{ paddingRight: 0.5, paddingY: 0.5 }}
              />
            }
            label={<Typography variant="body2">{t("master")}</Typography>}
            sx={{ marginRight: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="senior"
                checked={selectedDivisions.includes("senior")}
                onChange={handleCheckboxChange}
                color="default"
                size="small"
                sx={{ paddingRight: 0.5, paddingY: 0.5 }}
              />
            }
            label={<Typography variant="body2">{t("senior")}</Typography>}
            sx={{ marginRight: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="junior"
                checked={selectedDivisions.includes("junior")}
                onChange={handleCheckboxChange}
                color="default"
                size="small"
                sx={{ paddingRight: 0.5, paddingY: 0.5 }}
              />
            }
            label={<Typography variant="body2">{t("junior")}</Typography>}
            sx={{ marginRight: 0 }}
          />
        </Grid>
        {dateRangeError && (
          <Grid item xs={12}>
            <Typography color="error">{dateRangeError}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
