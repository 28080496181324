import React from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

const DeckCard = ({ deck, openEditModal, openDeleteDialog, openAddModal }) => (
  <Card variant="outlined" sx={{ boxShadow: 1, padding: 1 }}>
    <CardContent>
      <Grid container spacing={5}>
        <Grid container item xs={12} sm={6}>
          <Grid item xs={11}>
            <Typography variant="h5" component="div" marginBottom={1}>
              {deck.name_ja} ({deck.name_en})
            </Typography>
            <Stack spacing={1}>
              {(deck.conditions || []).map((condition, index) => (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  key={index}
                >
                  <Card
                    sx={{
                      border: "1px solid #bdbdbd",
                      width: { xs: "50px", sm: "50%" },
                      maxWidth: "80px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={deck.thumbnails[index]}
                      alt={deck.name_ja}
                      style={{
                        transform: "translateY(-5%)",
                        aspectRatio: "1.35/1",
                        objectPosition: "top",
                        marginBottom: "-5%",
                      }}
                      referrerPolicy="no-referrer"
                    />
                  </Card>
                  <Typography key={index} variant="body1" component="span">
                    {condition.card_name} {condition.num}枚以上
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => openEditModal(deck)} size="small">
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => openDeleteDialog(deck)}
              size="small"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          {deck.sub_deck_types && deck.sub_deck_types.length > 0 && (
            <Stack spacing={2} divider={<Divider />} marginBottom={2}>
              {deck.sub_deck_types.map((subDeck, index) => (
                <Grid container key={index}>
                  <Grid item xs={11}>
                    <Typography
                      variant="body1"
                      component="div"
                      marginBottom={0.5}
                    >
                      {subDeck.name_ja} ({subDeck.name_en})
                    </Typography>
                    <Stack spacing={1}>
                      {(subDeck.conditions || []).map((condition, index) => (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          key={index}
                        >
                          <Card
                            sx={{
                              border: "1px solid #bdbdbd",
                              width: { xs: "50px", sm: "50%" },
                              maxWidth: "80px",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={subDeck.thumbnails[index]}
                              alt={subDeck.name}
                              style={{
                                transform: "translateY(-5%)",
                                aspectRatio: "1.35/1",
                                objectPosition: "top",
                                marginBottom: "-5%",
                              }}
                              referrerPolicy="no-referrer"
                            />
                          </Card>
                          <Typography
                            key={index}
                            variant="body2"
                            component="span"
                          >
                            {condition.card_name} {condition.num}枚以上
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => openEditModal(deck, subDeck)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => openDeleteDialog(subDeck)}
                      size="small"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          )}
          <Grid container justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => openAddModal(deck.id)}
              startIcon={<AddIcon />}
            >
              サブタイプを追加
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default DeckCard;
