import React, { useEffect, useRef, useState } from "react";

import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { ContentCopy, Share } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import "../i18n";
import CustomLabel from "./CustomLabelComponent";

export default function ChartComponent({
  loading,
  items,
  earliestDate,
  latestDate,
}) {
  const { t } = useTranslation(["home_page"]);

  const chartContainerRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(0);
  const [chartWidth, setChartWidth] = useState(0);
  const imageContainerRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // モバイル端末（スマホ・タブレット）の場合のみ共有ボタンを表示
    setIsMobile(/Android|iPhone|iPad|iPod/i.test(navigator.userAgent));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
        setChartHeight(chartWidth * 0.65);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [loading, chartWidth]);

  const showAsImage = async () => {
    if (imageContainerRef.current) {
      setIsDialogOpen(true);
      await new Promise((resolve) => setTimeout(resolve, 100)); // 画像とタイトルが表示されるまで少し待つ

      const canvas = await html2canvas(imageContainerRef.current);
      setImageSrc(canvas.toDataURL("image/png"));
    }
  };

  const copyToClipboard = async () => {
    if (imageSrc) {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const item = new ClipboardItem({ "image/png": blob });
      await navigator.clipboard.write([item]);
      setSnackbarOpen(true);
    }
  };

  const shareImage = async () => {
    try {
      if (imageSrc) {
        const response = await fetch(imageSrc);
        const blob = await response.blob();
        const file = new File([blob], "chart.png", { type: "image/png" });

        await navigator.share({
          files: [file],
          title: "シティリーグ TOP16",
          text: `${earliestDate.replace(/-/g, "/")} - ${latestDate.replace(/-/g, "/")}`,
        });
      }
    } catch (error) {
      console.error("共有に失敗しました", error);
    }
  };

  const colors = [
    blueGrey[700],
    blueGrey[600],
    blueGrey[500],
    blueGrey[400],
    blueGrey[300],
  ];

  return (
    <>
      <Box ref={imageContainerRef} position="relative">
        {isDialogOpen && (
          <Stack marginLeft={1}>
            <Typography variant="h6">
              {t("deck_ranking_tab.graph_title")}
            </Typography>
            <Stack direction="row" gap={0.5}>
              <Typography variant="body1">
                {earliestDate.replace(/-/g, "/")}
              </Typography>
              <Typography variant="body1">ー</Typography>
              <Typography variant="body1">
                {latestDate.replace(/-/g, "/")}
              </Typography>
            </Stack>
          </Stack>
        )}
        <ResponsiveContainer
          width="100%"
          height={chartHeight}
          ref={chartContainerRef}
        >
          <PieChart onClick={showAsImage} style={{ cursor: "pointer" }}>
            <Pie
              data={items}
              startAngle={90}
              endAngle={-270}
              innerRadius="55%"
              outerRadius="85%"
              dataKey="top16_share"
              nameKey="type"
              label={(entry) => (
                <CustomLabel {...entry} totalItems={items.length} />
              )}
              labelLine={false}
              animationDuration={700}
            >
              {items.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    index < colors.length
                      ? colors[index % colors.length]
                      : alpha(
                          colors[colors.length - 1],
                          1 -
                            (index + 1 - colors.length) /
                              (items.length - colors.length),
                        )
                  }
                />
              ))}
            </Pie>
          </PieChart>
          {isDialogOpen && (
            <img
              src="/logo512.png"
              alt="Logo"
              style={{
                position: "absolute",
                right: "5px",
                bottom: "3.5%",
                width: "50px",
                height: "50px",
                opacity: 0.8,
              }}
            />
          )}
        </ResponsiveContainer>
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="md"
      >
        <DialogContent>
          {imageSrc && (
            <>
              <img src={imageSrc} style={{ width: "100%" }} alt="Graph" />
              <Box
                width="100%"
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {isMobile ? (
                  <IconButton onClick={shareImage}>
                    <Share />
                  </IconButton>
                ) : (
                  <IconButton onClick={copyToClipboard}>
                    <ContentCopy />
                  </IconButton>
                )}
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={() => setSnackbarOpen(false)}
        message={t("deck_ranking_tab.copy_message")}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "white",
            color: "black",
          },
        }}
      />
    </>
  );
}
