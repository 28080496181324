import { createContext } from "react";
import { useState } from "react";

export const DeckRankingTabContext = createContext(null);

export function DeckRankingTabProvider({ children }) {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [currentSelectedDivisions, setCurrentSelectedDivisions] =
    useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(null);

  return (
    <DeckRankingTabContext.Provider
      value={{
        error,
        setError,
        items,
        setItems,
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        currentSelectedDivisions,
        setCurrentSelectedDivisions,
        currentLanguage,
        setCurrentLanguage,
      }}
    >
      {children}
    </DeckRankingTabContext.Provider>
  );
}
