const users = [
  { username: "admin", password: "ktXTo[|>%Hwn,JL!E4ww" },
   { username: "admin1", password: "X5jK$|hk" },
];

const login = async (username, password) => {
  const user = users.find(
    (u) => u.username === username && u.password === password,
  );
  if (user) {
    const token = "dummy-token"; // トークンの代わりにダミーデータを使用
    const userData = { username: user.username, token };
    localStorage.setItem("user", JSON.stringify(userData));
    return userData;
  } else {
    throw new Error("Invalid username or password");
  }
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export { login, logout, getCurrentUser };
