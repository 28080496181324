import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import { TabContext, TabList } from "@mui/lab";
import { Box, Container, Tab } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

import { getCurrentUser } from "../../services/authService";
import DeckTypeTab from "./DeckTypeTab/DeckTypeTab";
import RankingFigureTab from "./RankingFigureTab/RankingFigureTab";

export default function AdminPage() {
  const [value, setValue] = useState("1");
  const user = getCurrentUser();
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (swiperRef.current) {
      swiperRef.current.slideTo(parseInt(newValue) - 1);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: 2, marginBottom: 8 }}>
      <Box typography="h5" sx={{ fontSize: "2rem", color: blueGrey[700] }}>
        ADMIN PAGE
      </Box>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="デッキタイプ管理" value="1" />
            <Tab label="ランキング画像" value="2" />
          </TabList>
        </Box>
      </TabContext>

      {/* 
            MUIのタブを使うと、タブが切り替わるたびに再レンダリングされる。
            その結果、FilterCondition の useEffect でデータ取得 (fetchData) が再実行され、
            「同期的な UI 更新中にサスペンドは禁止」というエラーが発生する。

            Swiper を使用することで、すべてのスライドを最初にレンダリングし、その後は表示・非表示を切り替えるのみとなる。
            これにより、FilterCondition の useEffect がタブ切り替えのたびに再実行される問題を防ぐ。
          */}
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => {
          setValue(String(swiper.activeIndex + 1));
        }}
        spaceBetween={50}
        style={{
          paddingTop: 10,
          paddingLeft: 1, // 中の要素の枠線分
          paddingRight: 1, // 同上
        }}
      >
        <SwiperSlide>
          <DeckTypeTab />
        </SwiperSlide>
        <SwiperSlide>
          <RankingFigureTab />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}
