import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";

import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import "../i18n";

dayjs.extend(utc);

export default function Stats({ stats }) {
  const { t } = useTranslation(["filter_condition_and_stats"]);

  const formatDateString = (dateStr) => {
    if (dateStr) {
      const [year, month, day] = dateStr.split("-");
      return { year, date: `${month}/${day}` };
    } else {
      return { year: "", date: "" };
    }
  };
  const formattedEarliestDate = formatDateString(stats.earliest_date);
  const formattedLatestDate = formatDateString(stats.latest_date);

  return (
    <div>
      <Box variant="body2" textAlign="center">
        <Grid container marginTop={2} columnSpacing={2} rowSpacing={0.3}>
          {["date_range", "total_events", "total_results"].map((label) => (
            <Grid item xs={4} alignContent="center">
              <Typography color="grey">{t(label)}</Typography>
            </Grid>
          ))}
          {[...Array(3)].map((_, index) => (
            <Grid item xs={4}>
              <Divider color="black" />
            </Grid>
          ))}
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="center" gap={0.5}>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  textAlign="left"
                  marginBottom={-0.5}
                >
                  {formattedEarliestDate.year}
                </Typography>
                {formattedEarliestDate.date}
              </Stack>
              <Box display="flex" alignItems="flex-end">
                ー
              </Box>
              <Stack direction="column">
                <Typography
                  variant="caption"
                  textAlign="left"
                  marginBottom={-0.5}
                >
                  {formattedLatestDate.year}
                </Typography>
                {formattedLatestDate.date}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {stats.event_num}
          </Grid>
          <Grid item xs={4}>
            {stats.result_num}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
