import { useContext, useRef, useState } from "react";

import html2canvas from "html2canvas";

import { Download } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { blueGrey, grey } from "@mui/material/colors";

import { FilterConditionContext } from "../../HomePage/components/FilterConditionContext";

export default function FigureComponent({
  earliestDate,
  latestDate,
  eventNum,
}) {
  const { startDate, endDate, dateRangeError, selectedDivisions } = useContext(
    FilterConditionContext,
  );
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const cardWidth = 224;
  const imageContainerRef = useRef(null);

  const fetchData = async () => {
    if (dateRangeError) return;

    try {
      const params = new URLSearchParams();
      if (startDate) params.append("start_date", startDate.toISOString());
      if (endDate) params.append("end_date", endDate.toISOString());
      params.append("divisions", JSON.stringify(selectedDivisions));
      params.append("language", "ja");

      const url =
        `${process.env.REACT_APP_API_URL}/deck_ranking` +
        (params.toString() ? `?${params}` : "");
      const response = await fetch(url);

      if (!response.ok) {
        const errorBody = await response.json();
        setError(
          "ERROR: " +
            (errorBody.detail || `API returned status ${response.status}`),
        );
        setItems([]);
      } else {
        const data = await response.json();
        const totalPoints = data.reduce((acc, item) => acc + item.point, 0);

        data.forEach((item) => {
          const share = totalPoints > 0 ? (item.point / totalPoints) * 100 : 0;
          item.pointShare = share;
        });

        setItems(data);
        setError(null);
      }
    } catch (e) {
      console.error("An unexpected error occurred:", e);
      setError("An unexpected error occurred.");
    }
  };

  const rankData = [
    { label: "S", color: "#FFFF00", min: 15, max: Infinity },
    { label: "A", color: "#D7D7D7", min: 10, max: 15 },
    { label: "B", color: "#FF962D", min: 5, max: 10 },
  ];

  const downloadImage = async () => {
    if (!imageContainerRef.current) return;
    const canvas = await html2canvas(imageContainerRef.current, {
      useCORS: true,
      backgroundColor: null,
    });
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "tier_list.png";
    link.click();
  };

  const formatDateString = (dateStr) => {
    if (dateStr) {
      const [year, month, day] = dateStr.split("-");
      return { year, date: `${month}/${day}` };
    } else {
      return { year: "", date: "" };
    }
  };
  const formattedEarliestDate = formatDateString(earliestDate);
  const formattedLatestDate = formatDateString(latestDate);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Button variant="outlined" sx={{ width: "50%" }} onClick={fetchData}>
          画像作成
        </Button>
      </Box>
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
      <Box width="1150px">
        <Stack
          backgroundColor={blueGrey[50]}
          sx={{
            borderRadius: 4,
          }}
          ref={imageContainerRef}
        >
          <Box padding={3} paddingBottom={1}>
            <Typography variant="h2" fontWeight="bold" color={blueGrey[800]}>
              シティリーグ Tier表
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack gap={1}>
                  <Typography
                    textAlign="center"
                    variant="h4"
                    color={blueGrey[700]}
                  >
                    期間
                  </Typography>
                  <Divider sx={{ borderColor: grey[700] }} />
                  <Typography
                    variant="h3"
                    color={blueGrey[800]}
                    fontWeight="bold"
                  >
                    <Stack
                      direction="row"
                      gap={1}
                      display="flex"
                      justifyContent="center"
                    >
                      <Stack direction="column">
                        <Typography
                          variant="h4"
                          textAlign="left"
                          marginBottom={-0.5}
                        >
                          {formattedEarliestDate.year}
                        </Typography>
                        {formattedEarliestDate.date}
                      </Stack>
                      <Box display="flex" alignItems="flex-end">
                        ー
                      </Box>
                      <Stack direction="column">
                        <Typography
                          variant="h4"
                          textAlign="left"
                          marginBottom={-0.5}
                        >
                          {formattedLatestDate.year}
                        </Typography>
                        {formattedLatestDate.date}
                      </Stack>
                    </Stack>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack gap={1}>
                  <Typography
                    textAlign="center"
                    variant="h4"
                    paddingX={2}
                    color={blueGrey[700]}
                  >
                    イベント数
                  </Typography>
                  <Divider sx={{ borderColor: grey[700] }} />
                  <Typography
                    variant="h3"
                    color={blueGrey[800]}
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {eventNum && eventNum}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Stack
            spacing={5}
            sx={{
              padding: 3,
              paddingY: 5,
              backgroundColor: blueGrey[400],
            }}
          >
            {rankData.map((rank, index) => {
              const rankItems = items
                .filter(
                  (item) =>
                    rank.min <= item.pointShare &&
                    item.pointShare < rank.max &&
                    item.type !== "その他",
                )
                .sort((a, b) => b.pointShare - a.pointShare);

              return (
                <>
                  {index > 0 && index < rankData.length && (
                    <Divider
                      sx={{
                        borderColor: grey[300],
                        borderWidth: "3px",
                        borderRadius: "3px",
                      }}
                    />
                  )}

                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "stretch",
                    }}
                  >
                    <Box
                      sx={{
                        flexShrink: 0,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{ backgroundColor: rank.color }}
                        width={10}
                        height="100%"
                        borderRadius={1}
                      />
                      <Typography
                        variant="h1"
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          color: rank.color,
                          marginLeft: 2,
                          marginRight: 5,
                        }}
                      >
                        {rank.label}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        flexWrap: "wrap",
                        flexGrow: 1,
                      }}
                    >
                      {rankItems.map((item, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            width: cardWidth,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            borderRadius: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: cardWidth,
                              height: `${cardWidth * (200 / 143) * 0.35}px`,
                              // 公式サイトのURLに直接アクセスするとCORSエラーが生じてhtml2canvasで画像生成できないので、
                              // バックエンドのプロキシを利用する
                              backgroundImage: `url(${process.env.REACT_APP_API_URL}/proxy-image/?url=${item.thumbnails[0]})`,
                              backgroundSize: "cover",
                              backgroundPosition: `center calc(${-cardWidth * (200 / 143) * 0.15}px)`,
                              borderRadius: 2,
                            }}
                          />
                          <Box height={80} marginTop={1}>
                            <Typography
                              variant="h4"
                              fontWeight="bold"
                              textAlign="center"
                              color="white"
                            >
                              {item.type}
                            </Typography>
                            <Typography
                              variant="h4"
                              textAlign="center"
                              color="white"
                            >
                              {item.point}pt
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                      {rankItems.length === 0 && (
                        <Box
                          sx={{
                            width: cardWidth,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            borderRadius: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: cardWidth,
                              height: `${cardWidth * (200 / 143) * 0.35}px`,
                            }}
                          />
                          <Box height={80} marginTop={1} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              );
            })}
          </Stack>
          <Box display="flex" justifyContent="flex-end" padding={3}>
            <Box
              height={100}
              width={200}
              overflow="hidden"
              marginRight={-2}
              sx={{
                backgroundImage: 'url("/logo_transparent.svg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Box>
        </Stack>
      </Box>
      <Button variant="outlined" onClick={downloadImage}>
        <Download />
      </Button>
    </>
  );
}
