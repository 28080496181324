import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Box, Card, CardMedia, Stack, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import { SearchDeckTabContext } from "../SearchDeckTab/SearchDeckTabContext";
import "./../i18n";

export default function TableComponent({ items, handleTabChange }) {
  const { t } = useTranslation(["home_page"]);

  const {
    setMainDeckType,
    setSubDeckType,
    setCardNameQueryList,
    setTriggerSearch,
  } = useContext(SearchDeckTabContext);

  const isXs = useMediaQuery("(max-width:600px)");
  const cardWidth = isXs ? 50 : 70;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      fontWeight: "bold",
      fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper} elevation={3}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell sx={{ paddingLeft: { xs: 1, sm: 2 } }}>
              {t("deck_ranking_tab.deck_type")}
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ paddingX: { xs: 1, sm: 2 } }}>
              TOP8
            </StyledTableCell>
            <StyledTableCell align="center" sx={{ paddingX: { xs: 1, sm: 2 } }}>
              TOP16
            </StyledTableCell>
            <StyledTableCell align="right" sx={{ paddingX: { xs: 1, sm: 2 } }}>
              POINT
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {items.map((row, index) => (
            <StyledTableRow key={row.type}>
              <StyledTableCell
                sx={{ paddingLeft: { xs: 1, sm: 2 }, paddingRight: 0 }}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={5} sm={4}>
                    <Link
                      onClick={() => {
                        setMainDeckType(
                          row.type === "Other" ? "その他" : row.type,
                        );
                        setSubDeckType("指定なし");
                        setCardNameQueryList([
                          { card_names: [], card_num: 1, condition: "以上" },
                        ]);
                        setTriggerSearch(true);
                        handleTabChange("4");
                      }}
                    >
                      {row.type}
                    </Link>
                  </Grid>
                  <Grid item xs={7} sm={8}>
                    <Stack direction="row" spacing={0.5} padding={0}>
                      {row.thumbnails.map((thumbnail, index) => (
                        <Card
                          key={index}
                          sx={{
                            height: (cardWidth / 1.35) * 0.85,
                            width: cardWidth,
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={thumbnail}
                            style={{
                              transform: "translateY(-15%)",
                              aspectRatio: "1.35/1",
                              objectPosition: "top",
                            }}
                            referrerPolicy="no-referrer"
                          />
                        </Card>
                      ))}
                      {row.thumbnails.length === 0 && (
                        <Box
                          sx={{
                            height: (cardWidth / 1.35) * 0.85,
                            width: cardWidth,
                          }}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </StyledTableCell>
              <StyledTableCell
                align="right"
                sx={{ paddingX: { xs: 1, sm: 2 } }}
              >
                {row.top8_share}%
              </StyledTableCell>
              <StyledTableCell
                align="right"
                sx={{ paddingX: { xs: 1, sm: 2 } }}
              >
                {row.top16_share}%
              </StyledTableCell>
              <StyledTableCell
                align="right"
                sx={{ paddingX: { xs: 1, sm: 2 } }}
              >
                {row.point}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
