import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { grey } from "@mui/material/colors";

import AceSpecTab from "./AceSpecTab/AceSpecTab";
import { AceSpecTabProvider } from "./AceSpecTab/AceSpecTabContext";
import CardUsageTab from "./CardUsageTab/CardUsageTab";
import { CardUsageTabProvider } from "./CardUsageTab/CardUsageTabContext";
import DeckRankingTab from "./DeckRankingTab/DeckRankingTab";
import { DeckRankingTabProvider } from "./DeckRankingTab/DeckRankingTabContext";
import SearchDeckTab from "./SearchDeckTab/SearchDeckTab";
import { SearchDeckTabProvider } from "./SearchDeckTab/SearchDeckTabContext";
import FilterCondition from "./components/FilterCondition";
import { FilterConditionProvider } from "./components/FilterConditionContext";
import Layout from "./components/Layout";
import Stats from "./components/Stats";
import "./i18n";

export default function HomePage() {
  const { t } = useTranslation(["home_page"]);

  const [stats, setStats] = useState("");

  const { tabName } = useParams();
  const navigate = useNavigate();

  // タブ名とタブの値の対応関係
  const tabMapping = useMemo(
    () => ({
      deck_ranking: "1",
      ace_spec: "2",
      card_usage: "3",
      search_deck: "4",
    }),
    [],
  );

  const reverseTabMapping = Object.fromEntries(
    Object.entries(tabMapping).map(([key, value]) => [value, key]),
  );

  // URL から初期タブ値を取得
  const initialTab = tabMapping[tabName] || "1";
  const [tabValue, setTabValue] = useState(initialTab);

  useEffect(() => {
    // URLの変更時にタブの値を更新
    if (tabMapping[tabName]) {
      setTabValue(tabMapping[tabName]);
    } else {
      // 無効なURLの場合、デフォルトのタブへリダイレクト
      navigate("/deck_ranking", { replace: true });
    }
  }, [tabName, navigate, tabMapping]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    navigate(`/${reverseTabMapping[newValue]}`);
  };

  const MyTab = ({ value, label, ...props }) => (
    <Tab
      label={
        <Typography variant="body2" fontWeight={tabValue === value && "bold"}>
          {label}
        </Typography>
      }
      value={value}
      sx={{
        position: "relative",
        paddingX: 1,
        paddingY: 1,
        minWidth: 0,
        minHeight: 0,
        background: tabValue !== value && grey[200],
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        border: tabValue === value && `1px solid grey`,
        borderBottom: tabValue === value ? "none" : "1px solid grey",
      }}
      {...props} // タブとして動作させるために必要（タブリストから渡されてる？）
    />
  );

  const TabGapBox = () => (
    <Box
      borderBottom="1px solid grey"
      width="8px"
      flexShrink={0} // コンテンツがなくても幅を維持
    />
  );

  return (
    <FilterConditionProvider>
      <DeckRankingTabProvider>
        <AceSpecTabProvider>
          <CardUsageTabProvider>
            <SearchDeckTabProvider>
              <Layout>
                <FilterCondition setStats={setStats} />
                <Stats stats={stats} />
                <Box marginTop={2}>
                  <TabContext value={tabValue}>
                    <Box display="flex">
                      <TabList
                        onChange={(event, newValue) => {
                          handleTabChange(newValue);
                        }}
                        variant="scrollable"
                        scrollButtons={false}
                        TabIndicatorProps={{
                          // デフォルトのタブインディケーターは横幅が親のタブと一致してしまいダサいので、デフォルトのタブインディケーターはflexかつ透明にして、
                          // 子要素に自作のインディケーターを割り当てる
                          sx: {
                            paddingX: 1,
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "transparent",
                          },
                          children: (
                            <span
                              style={{
                                width: "100%", // 幅はデフォルトのタブインディケーターのpadding分小さくなる
                                backgroundColor: "#FE6B8B",
                              }}
                            />
                          ),
                        }}
                        sx={{
                          minHeight: 0,
                        }}
                      >
                        {/* タブ要素はタブリスト要素直下に置かれる必要があり、TabGapBoxがある以上Mapだと<></>で覆わないといけないので上手く動作しなかった */}
                        <MyTab value="1" label={t("deck_ranking")} />
                        <TabGapBox />
                        <MyTab value="2" label={t("ace_spec")} />
                        <TabGapBox />
                        <MyTab value="3" label={t("card_usage")} />
                        <TabGapBox />
                        <MyTab value="4" label={t("search_deck")} />
                      </TabList>
                      <Box borderBottom="1px solid grey" flexGrow={1} />
                    </Box>

                    <TabPanel
                      value="1"
                      sx={{ padding: 0, paddingTop: { xs: 0, sm: 3 } }}
                    >
                      <DeckRankingTab
                        handleTabChange={handleTabChange}
                        earliestDate={stats.earliest_date}
                        latestDate={stats.latest_date}
                      />
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: 0, paddingTop: 3 }}>
                      <AceSpecTab handleTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel value="3" sx={{ padding: 0, paddingTop: 3 }}>
                      <CardUsageTab handleTabChange={handleTabChange} />
                    </TabPanel>
                    <TabPanel value="4" sx={{ padding: 0, paddingTop: 3 }}>
                      <SearchDeckTab />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Layout>
            </SearchDeckTabProvider>
          </CardUsageTabProvider>
        </AceSpecTabProvider>
      </DeckRankingTabProvider>
    </FilterConditionProvider>
  );
}
