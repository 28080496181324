export const fetchDeckList = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/main_deck_type?language=ja`,
  );
  if (!response.ok) {
    throw new Error("Failed to fetch deck list");
  }
  return await response.json();
};

export const fetchCardNames = async (searchQuery) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/cards?search=${encodeURIComponent(
      searchQuery,
    )}&language=ja`,
  );
  if (!response.ok) {
    throw new Error("Failed to fetch card names");
  }
  return await response.json();
};

export const saveDeck = async (url, formState) => {
  const response = await fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formState),
  });

  if (!response.ok) {
    throw new Error("Failed to save deck");
  }

  return await response.json();
};

export const addDeck = async (url, formState) => {
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formState),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData;
  }

  return await response.json();
};

export const deleteDeck = async (url) => {
  const response = await fetch(url, {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error("Failed to delete deck");
  }
};

export const updateDeckPriority = async (deckList) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/update_deck_priority/`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        decks: deckList.map((deck, index) => ({
          id: deck.id,
          priority: index,
        })),
      }),
    },
  );

  if (!response.ok) {
    throw new Error("Failed to update deck priorities");
  }

  return await response.json();
};
