import { useEffect } from "react";
import React from "react";

import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { BugReport, X } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { blueGrey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#FE6B8B" },
    secondary: { main: "#FF8E53" },
    background: { default: "#ffffff" },
    button: { main: blueGrey[100], dark: blueGrey[300] },
  },
});

const Layout = ({ children }) => {
  const { t, i18n } = useTranslation(["home_page"]);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("G-RJ2C336DGR");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          paddingY: 2,
          minHeight: "100dvh",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="stretch">
          <img
            src={"/logo_white.svg"}
            style={{ height: "60px", width: "110px", objectFit: "cover" }}
            alt="Logo"
          />
          <Stack gap={1} alignItems="flex-end">
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => handleLanguageChange("en")}
                sx={{ padding: 0 }}
              >
                <img src="/flags/en.png" alt="English" width={24} height={24} />
              </IconButton>
              <IconButton
                onClick={() => handleLanguageChange("ja")}
                sx={{ padding: 0 }}
              >
                <img src="/flags/jp.png" alt="日本語" width={24} height={24} />
              </IconButton>
            </Stack>
            <Link
              href="https://x.com/ptcgdatabase"
              target="_blank" // 新しいタブで開く
              rel="noopener noreferrer" // セキュリティ対策
              color="inherit"
              underline="none"
              sx={{
                borderBottom: "1px solid grey",
                display: "flex",
              }}
            >
              <BugReport fontSize="small" />
              <Typography variant="caption">{t("report_bug")}</Typography>
            </Link>
          </Stack>
        </Box>
        <Box flexGrow={1}>{children}</Box>
        <Divider />
        <Box display="flex" justifyContent="center">
          <IconButton
            sx={{ padding: 0 }}
            href="https://x.com/ptcgdatabase"
            target="_blank" // 新しいタブで開く
            rel="noopener noreferrer" // セキュリティ対策
          >
            <X />
          </IconButton>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
