import { createContext } from "react";
import React, { useState } from "react";

import { useSearchParams } from "react-router-dom";

export const SearchDeckTabContext = createContext(null);

export function SearchDeckTabProvider({ children }) {
  const [searchParams] = useSearchParams();

  const [mainDeckType, setMainDeckType] = useState(
    searchParams.get("main_deck_type") || "指定なし",
  );
  const [subDeckType, setSubDeckType] = useState(
    searchParams.get("sub_deck_type") || "指定なし",
  );
  const [cardNameQueryList, setCardNameQueryList] = useState(
    searchParams.get("card_name_query_list")
      ? JSON.parse(searchParams.get("card_name_query_list"))
      : [{ card_names: [], card_num: 1, condition: "以上" }],
  );
  const [items, setItems] = useState({
    stats: [],
    decks_by_category: [],
    time_history: [],
  });
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [currentSelectedDivisions, setCurrentSelectedDivisions] =
    useState(null);
  const [triggerSearch, setTriggerSearch] = useState(false);

  return (
    <SearchDeckTabContext.Provider
      value={{
        mainDeckType,
        setMainDeckType,
        subDeckType,
        setSubDeckType,
        cardNameQueryList,
        setCardNameQueryList,
        items,
        setItems,
        currentStartDate,
        setCurrentStartDate,
        currentEndDate,
        setCurrentEndDate,
        currentSelectedDivisions,
        setCurrentSelectedDivisions,
        triggerSearch,
        setTriggerSearch,
      }}
    >
      {children}
    </SearchDeckTabContext.Provider>
  );
}
